.tab-handle {
  @apply px-4 py-2 bg-white border rounded-md lg:rounded-b-none lg:border-b-0 text-gray-800 bg-gray-100;
}

.tab-handle.active {
  @apply bg-white font-semibold;
}

.tab-handle.active span {
  @apply font-normal;
}
