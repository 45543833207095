.site-card-border-less-wrapper {
  padding: 10px;
}
.ant-checkbox-group-item {
  display: flex;
  margin-right: 0;
  margin-top: 3px;
}

.tab-handle-scheduler {
  @apply px-4 py-2 bg-white border rounded-md rounded-b-none border-b-0 text-gray-800 bg-gray-100 flex items-center justify-between cursor-pointer relative;
}

.tab-handle-scheduler.active {
  @apply bg-white font-semibold border-green-brand;
}

.tab-handle-scheduler.active span {
  @apply font-normal;
}
