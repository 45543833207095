@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

html {
  overflow-y: scroll;
}

body {
  font-family: "Roboto", sans-serif;
}

.text-large {
  font-size: large;
}

.text-xlarge {
  font-size: x-large;
}
