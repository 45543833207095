.SchedulerWrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  @apply bg-white;
}

.LeftPanel {
  width: 15%;

  @apply border bg-gray-50 select-none;
}

.Device {
  width: 100%;
  padding: 7px;
  cursor: pointer;
  max-height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @apply border-b bg-gray-50 flex items-center px-2 font-semibold truncate;
}

.ChildDevice {
  width: 100%;
  text-align: center;
  color: black;
  /* background-color: rgb(243, 243, 243); */
  min-height: 30px;
  max-height: 30px;

  @apply border-b bg-gray-50 flex items-center justify-center px-2 text-xs;
}

.RightPanel {
  width: 100%;
  background-color: rgb(243, 243, 243);
  /* overflow-x: scroll; */
}

.SquarePanel {
  /* overflow-x: scroll; */
  display: flex;
}

.GroundName {
  text-align: center;
  padding: 10px;
  height: 30px;

  @apply flex items-center justify-center bg-gray-50;
}

.MinutesList {
  display: flex;
  flex-direction: row;
}

.Minute {
  width: 40px;
  height: 30px;
  inset: 0px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;

  @apply border border-l-0 border-t-0 bg-gray-50 select-none;
}

.Hour {
  text-align: center;
  min-height: 30px;
  max-height: 30px;
  width: 160px;
  padding: 5px;
  cursor: context-menu;

  @apply border border-l-0 bg-gray-50 select-none;
}

.Square {
  width: 40px;
  height: 30px;
  border: 1px solid rgb(238, 238, 238);
  border-left: 0px;
  border-top: 0px;

  @apply bg-white;
}

.SquareDisabled {
  width: 40px;
  height: 30px;
  border: 1px solid rgb(238, 238, 238);
  border-left: 0px;
  border-top: 0px;

  @apply bg-gray-50;
}

.SquareVerticalLine {
  width: 40px;
  /* position: relative; */
}

.SquareBlock {
  display: flex;
  /* position: relative; */
}

.HourBockWrapper {
  display: flex;
}

.Moving {
  background-color: grey !important;
}

.SelectionStart {
  background-color: teal !important;
}

.NodeStyles {
  position: absolute;
  background-color: rgb(106, 106, 255);
  border: 1px solid indigo;
}

.NodeStyles::after {
  content: "";
  cursor: w-resize;
  width: 5px;
  height: 30px;
  position: absolute;
  right: 0;
}

.NodeStyles::before {
  content: "";
  cursor: w-resize;
  width: 5px;
  height: 30px;
  position: absolute;
  left: 0;
}

.NodeStylesNotSaved {
  position: absolute;
  background-color: rgb(255, 106, 138);
  border: 1px solid indigo;
}

.NodeStylesNotSaved::after {
  content: "";
  cursor: w-resize;
  width: 5px;
  height: 30px;
  position: absolute;
  right: 0;
}

.NodeStylesNotSaved::before {
  content: "";
  cursor: w-resize;
  width: 5px;
  height: 30px;
  position: absolute;
  left: 0;
}

.NodeWrapper {
  /* //position: relative; */
}

.InnerButton {
  position: absolute;
  top: -16px;
  right: 0;
  font-size: 25px;
}

.EventElement {
  @apply rounded absolute bg-gradient-to-r from-blue-600 to-blue-500 flex items-center justify-end px-1 opacity-100;

  height: 24px;
}

.NewEventElement {
  @apply rounded absolute bg-gradient-to-r from-blue-600 to-blue-500 flex items-center justify-end px-1 opacity-80;

  height: 24px;
}

.DeletedEventElement {
  @apply rounded absolute bg-gradient-to-r from-red-500 to-red-300 flex items-center justify-end px-1 opacity-80;

  height: 2px;
}

.EventDelete {
  @apply flex bg-white rounded-sm h-4 w-4 text-blue-600 text-xs items-center justify-center;
}

.DisabledEventElement {
  @apply rounded absolute bg-gradient-to-r from-blue-400 to-blue-300 flex items-center justify-end px-1 opacity-80;

  height: 24px;
}

.EventElement:hover .EventDelete {
  display: flex;
}

.WateringSquare {
  background-color: #f5f8ff;
  /* Light blue color */
}

.ReadOnlySquare {
  opacity: 0.5;
}

.EventContent {
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1px 3px;
  color: #ffffff;
  width: 100%;
}

.EventElement,
.NewEventElement,
.DeletedEventElement,
.DisabledEventElement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
}

.EventDelete {
  margin-left: auto;
  height: 16px;
  width: 16px;
  font-size: 10px;
}

/* Add this at the end of the file */
:global(.ant-tooltip-inner) {
  max-width: 300px;
  word-wrap: break-word;
}